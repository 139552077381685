













































































import { FULL_100, Zero } from '@/constants'
import { bnHelper } from '@/helpers/bignumber-helper'
import { FixedNumber } from '@ethersproject/bignumber'
import { reduce } from 'lodash'
import { Observer } from 'mobx-vue'
import { Vue, Component, Inject } from 'vue-property-decorator'
import { ClaimerPoolsViewModel } from '../viewmodels/claimer-pools-viewmodel'

@Observer
@Component({
  components: {}
})
export default class VestingScheduleForm extends Vue {
  @Inject() vm!: ClaimerPoolsViewModel
  vestingSchedulers = [{ dateTime: '', hour: '', percentage: '', index: 0 }]
  error = ''

  addRow() {
    const lastIndex = this.vestingSchedulers[this.getLength - 1].index
    this.vestingSchedulers.push({ dateTime: '', hour: '', percentage: '', index: lastIndex + 1 })
  }

  removeRow(index) {
    if (this.getLength > 1) this.vestingSchedulers.splice(index, 1)
  }

  reset() {
    if (this.$refs.form) {
      ;(this.$refs.form as any).resetValidation()
      this.vestingSchedulers = [{ dateTime: '', hour: '', percentage: '', index: 0 }]
    }
  }
  validate() {
    return (this.$refs.form! as any).validate()
  }
  percentageOnChange() {
    this.error = ''
  }

  getData() {
    const configs = this.vestingSchedulers.map(item => {
      const date = Date.parse(new Date(item.dateTime + ' ' + item.hour) as any) / 1000
      return {
        date,
        percentage: bnHelper.toDecimalString(item.percentage)
      }
    })
    const totalPercent = configs.reduce((a: any, b) => {
      if (a.isZero()) return FixedNumber.from(b.percentage)
      else return a.addUnsafe(FixedNumber.from(b.percentage))
    }, Zero)
    if (totalPercent.toString() !== bnHelper.toDecimals(FULL_100).toString()) {
      this.error = 'Total percentage must be 100%'
      return null
    }
    return configs
  }

  get getLength() {
    return this.vestingSchedulers.length
  }
}
